import React, { useState, useEffect } from 'react';
import { TextField, Box, Chip, List, ListItem, ListItemText, Paper, IconButton } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import { useFetchIcdCodesQuery } from '../../apis/chart';
import theme from '../../styles/theme'; // Import the global theme

const ICDCodeSearch = ({ onAddCode }) => {
    const [searchQuery, setSearchQuery] = useState('');
    const { data: icdCodes = { results: [] }, refetch } = useFetchIcdCodesQuery(searchQuery, { skip: searchQuery.length <= 1 });

    useEffect(() => {
        if (searchQuery.length > 1) {
            refetch();
        }
    }, [searchQuery, refetch]);

    const handleSelectCode = (code) => {
        console.log(`code before onAddCode called in search: ${JSON.stringify(code, null, 4)}`)
        onAddCode(code);
        setSearchQuery('');
    };

    const handleClearSearch = () => {
        setSearchQuery('');
    };

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', marginBottom: 2 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 2 }}>
                <Box sx={{ display: 'flex', alignItems: 'center', flexGrow: 1, marginRight: 2 }}>
                    <TextField
                        label="Search New Codes"
                        variant="outlined"
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                        autoComplete="off"
                        sx={{ flexGrow: 1 }}
                    />
                    {searchQuery && (
                        <IconButton onClick={handleClearSearch}>
                            <ClearIcon />
                        </IconButton>
                    )}
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Chip
                        label="AI Identified"
                        sx={{
                            marginRight: 1,
                            backgroundColor: theme.palette.codes.ai.background,
                            color: theme.palette.codes.ai.color,
                        }}
                    />
                    <Chip
                        label="User Added"
                        sx={{
                            backgroundColor: theme.palette.codes.user.background,
                            color: theme.palette.codes.user.color,
                        }}
                    />
                </Box>
            </Box>
            {searchQuery.length > 1 && icdCodes.results?.length > 0 && (
                <Paper elevation={3} sx={{ maxHeight: 200, overflow: 'auto' }}>
                    <List>
                        {icdCodes.results.map((code) => (
                            <ListItem button key={code.code} onClick={() => handleSelectCode(code)}>
                                <ListItemText primary={`${code.code} - ${code.description}`} />
                            </ListItem>
                        ))}
                    </List>
                </Paper>
            )}
        </Box>
    );
};

export default ICDCodeSearch;
