import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import CodeCard from './CodeCard';

const FinalizedCodes = ({
    completedCodes,
    handleRemoveFromCompleted,
    aiIdentifiedConditions,
    userSelectedCodes,
    onDragEnd,
    handleUnlockCodes,
    handleFinalizeClick,
    chart
}) => {
    return (
        <Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginLeft: 2, marginBottom: 1 }}>
                <Typography variant="h6">Finalized Codes</Typography>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={chart?.is_processed ? handleUnlockCodes : handleFinalizeClick}
                >
                    {chart?.is_processed ? "Unlock" : "Finalize"}
                </Button>
            </Box>
            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="completedCodes">
                    {(provided) => (
                        <Box
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                            sx={{ margin: 2, overflowY: 'auto', height: '100%' }}
                        >
                            {completedCodes.map((code, index) => (

                                <Draggable key={code.code} draggableId={code.code} index={index}>

                                    {(provided) => (
                                        <Box
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            sx={{
                                                padding: 1,
                                            }}
                                        >
                                            <CodeCard
                                                key={code.code}
                                                code={code}
                                                action={handleRemoveFromCompleted}
                                                chevronLeft
                                                aiIdentifiedConditions={aiIdentifiedConditions}
                                                userSelectedCodes={userSelectedCodes}
                                                {...provided.dragHandleProps}
                                            />
                                        </Box>
                                    )}
                                </Draggable>
                            ))}
                            {provided.placeholder}
                        </Box>
                    )}
                </Droppable>
            </DragDropContext>
        </Box>
    );
};

export default FinalizedCodes;
