import React, { useState, useEffect, useMemo } from 'react';
import { TextField, IconButton, Box, Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import { useCreateOrUpdateCodedChartMutation, useUpdateChartMutation } from '../../apis/chart';
import { useUpdatePatientMutation } from '../../apis/patients';

const PatientInfoEditable = ({ chart, onSave }) => {
  const humanCodedChart = useMemo(() => {
    return chart?.coded_charts.find(codedChart => codedChart.source === 'Human') || {};
  }, [chart?.coded_charts]);

  const [isEditable, setIsEditable] = useState(false);
  const [editedChart, setEditedChart] = useState({ ...chart });
  const [editedCodedChart, setEditedCodedChart] = useState({ ...humanCodedChart });
  const [editedPatient, setEditedPatient] = useState({ ...chart.patient });
  const [updateChart] = useUpdateChartMutation();
  const [updatePatient] = useUpdatePatientMutation();
  const [createOrUpdateCodedChart] = useCreateOrUpdateCodedChartMutation();

  useEffect(() => {
    // Update editedChart
    setIsEditable(false);

    setEditedChart({
      ...chart,
      date_of_service_start: chart.date_of_service_start,
      date_of_service_end: chart.date_of_service_end,
    });

    setEditedCodedChart({
      ...humanCodedChart,
      out_of_scope_reason: humanCodedChart?.out_of_scope_reason || '',
    });

    // Update editedPatient
    setEditedPatient({
      ...chart.patient,
      dob: chart.patient.dob,
    });
  }, [chart, humanCodedChart]);



  const handleEditToggle = () => {
    setIsEditable(!isEditable);
  };

  const handleChangePatient = (e) => {
    const { name, value } = e.target;
    setEditedPatient((prev) => ({ ...prev, [name]: value }));
  };

  const handleChangeChart = (e) => {
    const { name, value } = e.target;
    setEditedChart((prev) => ({ ...prev, [name]: value }));
  };

  const handleOutOfScopeReasonChange = (e) => {
    const { value } = e.target;
    setEditedCodedChart((prev) => ({ ...prev, out_of_scope_reason: value }));
  };

  const handleInfoSave = () => {
    updatePatient(editedPatient);  // Update patient information
    updateChart({
      id: chart.uuid,
      date_of_service_start: editedChart.date_of_service_start,
      date_of_service_end: editedChart.date_of_service_end,
    });  // Update chart details

    // Check if there's an existing Human CodedChart (check id exists)
    const humanCodedChartId = humanCodedChart?.uuid;

    createOrUpdateCodedChart({
      id: humanCodedChartId,  // If this exists, it will trigger an update (PATCH)
      chartId: chart.uuid,    // Always provide the chart ID
      out_of_scope_reason: editedCodedChart.out_of_scope_reason,  // Use editedCodedChart for the correct field
    });

    setIsEditable(false);
    onSave();
  };

  // Options for the select
  const outOfScopeOptions = [
    'Not codable - no clinical info',
    'Not codable - not a visit',
    'Not codable - not signed',
    'Not codable - incomplete chart/failed split',
    'Not codable - other',
    'Codable - Not a 2023 DOS',
    'Codable - Not a F2F Encounter'
  ];

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, flexWrap: 'wrap' }}>
      <TextField
        name="name"
        label="Name"
        value={editedPatient.name}
        onChange={handleChangePatient}
        disabled={!isEditable}
        size="small"
      />
      <TextField
        name="dob"
        label="DOB"
        value={editedPatient.dob}
        onChange={handleChangePatient}
        disabled={!isEditable}
        size="small"
      />
      <TextField
        name="gender"
        label="Gender"
        value={editedPatient.gender}
        onChange={handleChangePatient}
        disabled={!isEditable}
        size="small"
      />
      <TextField
        name="date_of_service_start"
        label="DOS start"
        value={editedChart.date_of_service_start}
        onChange={handleChangeChart}
        disabled={!isEditable}
        size="small"
      />
      <TextField
        name="date_of_service_end"
        label="DOS end"
        value={editedChart.date_of_service_end}
        onChange={handleChangeChart}
        disabled={!isEditable}
        size="small"
      />
      <FormControl size="small" sx={{ minWidth: 300 }} disabled={!isEditable}>
        <InputLabel id="out-of-scope-reason-label">Out of Scope Reason</InputLabel>
        <Select
          labelId="out-of-scope-reason-label"
          name="out_of_scope_reason"
          value={editedCodedChart.out_of_scope_reason}
          onChange={handleOutOfScopeReasonChange}
          label="Out of Scope Reason"
        >
          {outOfScopeOptions.map((option) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <IconButton onClick={isEditable ? handleInfoSave : handleEditToggle}>
        {isEditable ? <SaveIcon /> : <EditIcon />}
      </IconButton>
    </Box>
  );
};

export default PatientInfoEditable;
