import React, { useEffect, useState } from 'react';
import { Box, Typography, Switch } from '@mui/material';

const StatusToggles = ({ chart, handleToggleChange }) => {
    const [deficiencyChecked, setDeficiencyChecked] = useState(false);
    const [blockedChecked, setBlockedChecked] = useState(false);
    const [submittableChecked, setSubmittableChecked] = useState(false);

    // Update state when chart changes
    useEffect(() => {
        if (chart) {
            setDeficiencyChecked(chart.documentation_deficiency || false);
            setBlockedChecked(chart.blocked || false);
            setSubmittableChecked(chart.submittable || false);
        }
    }, [chart]);

    const handleDeficiencyToggle = () => {
        const newValue = !deficiencyChecked;
        setDeficiencyChecked(newValue);
        handleToggleChange('documentation_deficiency', newValue);
    };

    const handleBlockedToggle = () => {
        const newValue = !blockedChecked;
        setBlockedChecked(newValue);
        handleToggleChange('blocked', newValue);
    };

    const handleSubmittableToggle = () => {
        const newValue = !submittableChecked;
        setSubmittableChecked(newValue);
        handleToggleChange('submittable', newValue);
    };

    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                width: '100%',
                padding: 2,
                gap: 3,
                backgroundColor: '#f5f5f5',
                border: '1px solid #ccc',
                borderRadius: 2,
            }}
        >
            {/* Documentation Deficiency Toggle */}
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant="body1" sx={{ marginRight: 1 }}>
                    Out of Scope
                </Typography>
                <Switch
                    checked={deficiencyChecked}
                    onChange={handleDeficiencyToggle}
                />
            </Box>

            {/* Blocked Toggle */}
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant="body1" sx={{ marginRight: 1 }}>
                    Blocked
                </Typography>
                <Switch
                    checked={blockedChecked}
                    onChange={handleBlockedToggle}
                />
            </Box>

            {/* Submittable Toggle */}
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant="body1" sx={{ marginRight: 1 }}>
                    Submittable
                </Typography>
                <Switch
                    checked={submittableChecked}
                    onChange={handleSubmittableToggle}
                />
            </Box>
        </Box>
    );
};

export default StatusToggles;
